import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractEntityTypeService,
  BaseDashboardComponent,
  DynamicDialogService,
  EntityType,
  Filter,
  FilterExpressions,
  FilterGroup,
  FilterOperations,
  GenericRelation,
  ObjectsUtilityService,
  PageContextService,
  ResultObject,
  UtilityService,
  ViewMode,
} from '@prg/prg-core-lib';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { first, Subscription } from 'rxjs';
import { DepartmentService } from '../../../Core/services/department-service/department.service';
import { WorkOrder } from '../../../work-orders/models/work-order-model';
import { Ticket, TicketTypes } from '../../models/ticket.model';
import { TicketService } from '../../services/ticket.service';
import { TicketFormComponent } from './components/ticket-form/ticket-form.component';
import { TICKETS_FILTERS } from './tickets-filters';
import {
  PrgTicketsListSettingsModel,
  TICKET_LIST_SETTINGS,
} from './ticktes-list-settings';

@Component({
  selector: 'tickets-list',
  templateUrl: './tickets-list.component.html',
  styleUrls: ['./tickets-list.component.scss'],
})
export class TicketsListComponent
  extends BaseDashboardComponent
  implements OnInit, OnDestroy
{
  @Input() itemId: string;
  @Input() isEditMode: boolean = false;

  /**
   * The custom settings for this component
   * @param itemSettings
   */
  @Input('itemSettings') set itemSettings(
    itemSettings: PrgTicketsListSettingsModel
  ) {
    if (itemSettings != null) {
      this._itemSettings = this.objectsUtilityService.clone(itemSettings);
    }
  }

  /**
   * Getter for item settings
   * @returns {any}
   */
  get itemSettings(): PrgTicketsListSettingsModel {
    return this._itemSettings;
  }

  /**
   * aux settings
   * @private
   */
  private _itemSettings: PrgTicketsListSettingsModel;

  /**
   * Height of the chart
   * @type {string}
   */
  public height: string;
  /**
   * Width of the chart
   * @type {string}
   */
  public width: string;

  /**
   * The size of the item dashboard
   */
  @Input('sizeItem') set sizeItem(sizeItem: any) {
    if (sizeItem?.height != null && sizeItem?.width != null) {
      setTimeout(() => {
        this.height = sizeItem?.height.toString() + 'px';
        this.width = sizeItem?.width.toString() + 'px';
      }, 20);
    }
  }

  /**
   * Variable to unsubscribe observables
   * @type {Subscription[]}
   * @private
   */
  private subscription: Subscription[] = [];
  public entityTypeName: string = 'ticket';

  public ticketsList: Ticket[] = [];
  public totalTickets: number = 0;
  private ticketFormRef: DynamicDialogRef;

  public ticketsFiltersFields = TICKETS_FILTERS;

  public selectedTicket: Ticket;

  public pageIndex: number = 0;
  public pageSize: number = 10;
  /**
   * filter group
   */
  private filterGroup: FilterGroup = new FilterGroup({
    orderCollection: [],
    filterCollections: [],
    pageIndex: this.pageIndex,
    pageSize: this.pageSize,
  });

  private filterTicketType: Filter = new Filter({
    startGroup: true,
    propertyName: 'TicketTypeId',
    value: 'tickettypes.ticket',
    filterExpression: FilterExpressions.And,
    filterOperation: FilterOperations.EqualTo,
  });

  /**
   * Show/Hide work order modal
   * @type {boolean}
   */
  public displayWorkOrderModal: boolean = false;

  /**
   * new Work Order
   * @type {WorkOrder}
   */
  public newWorkOrder: WorkOrder = null;

  /**
   * work order form view mode
   * @type {ViewMode}
   */
  public workOrderViewMode: ViewMode = ViewMode.Edit;

  /**
   * Work order entity type
   * @type {EntityType}
   */
  public entityTypeWorkOrder: EntityType = null;

  public canCreateTicket: boolean = true;

  constructor(
    private objectsUtilityService: ObjectsUtilityService,
    private dialogService: DynamicDialogService,
    private ticketService: TicketService,
    private pageContext: PageContextService,
    private translateService: TranslateService,
    private utilityService: UtilityService,
    private entityTypeService: AbstractEntityTypeService,
    private departmentService: DepartmentService
  ) {
    super();
    TicketsListComponent.key = TICKET_LIST_SETTINGS.key;
    TicketsListComponent.configurationSettings =
      TICKET_LIST_SETTINGS.configurationSettings;
    TicketsListComponent.staticSettings = TICKET_LIST_SETTINGS.staticSettings;
    if (this.departmentService.currentUserIsDepartmentManager()) {
      const departments = this.departmentService.getUserDepartmentsValue();
      if (departments != null) {
        this.canCreateTicket = departments.some(
          (x) => x.canCreateTicket == true
        );
      } else {
        this.canCreateTicket = false;
      }
    }
  }

  ngOnInit() {
    if (this._itemSettings == null) {
      this._itemSettings = this.objectsUtilityService.clone(
        TICKET_LIST_SETTINGS.staticSettings
      );
    }
  }

  /**
   * ngOnDestroy
   */
  public ngOnDestroy(): void {
    this.subscription.forEach((subs) => {
      subs.unsubscribe();
    });
  }

  private openModalToManageTicket(ticket: Ticket, title: string): void {
    this.ticketFormRef = this.dialogService.openDialog(TicketFormComponent, {
      header: title,
      autoZIndex: false,
      modal: true,
      draggable: false,
      styleClass: 'container-ticket-dialog-form',
      style: { height: '90vh', 'min-height': '90vh', 'max-width': '1400px' },
      data: {
        entity: ticket,
      },
    });
    this.ticketFormRef.onClose.pipe(first()).subscribe(() => {
      this.getTickets();
    });
  }

  /**
   * this funcion open a dynamic modal to add new ticket
   */
  public addNewTicket(): void {
    const newTicket = new Ticket();
    /*newTicket.ticketStateId = TicketStates.waiting;*/
    newTicket.clientId = '';
    newTicket.isPublic = false;
    newTicket.ticketTypeId = TicketTypes.Ticket;
    const popupTitle: string = this.translateService.instant(
      'pages.tickets.components.ticket-form.new-ticket.label'
    );
    this.openModalToManageTicket(newTicket, popupTitle);
  }

  /**
   * this function makes the request to get the data to be showed in the table
   */
  private async getTickets(): Promise<void> {
    if (
      this.filterGroup.filterCollections == null ||
      this.filterGroup.filterCollections.length == 0
    ) {
      this.filterGroup.filterCollections = [];
      this.filterGroup.filterCollections.push(this.filterTicketType);
    } else {
      this.filterGroup.filterCollections.unshift(this.filterTicketType);
    }

    const resultObject: ResultObject = await this.ticketService.getTickets(
      this.filterGroup
    );
    if (resultObject != null) {
      this.ticketsList = resultObject.entity;
      this.totalTickets = resultObject.data.TotalItems;
    }
  }

  public receiveFilterGroup(filterGroupFromFilters: FilterGroup) {
    this.pageIndex = 0;
    this.filterGroup.filterCollections =
      filterGroupFromFilters.filterCollections;
    this.filterGroup.orderCollection = filterGroupFromFilters.orderCollection;
    this.getTickets();
  }

  public onPageChange(event): void {
    this.pageIndex = event.page;
    this.filterGroup.pageIndex = this.pageIndex;

    this.getTickets();
  }

  public onTicketSelect(event: any): void {
    this.selectedTicket = event.value;
    this.pageContext.setVariableData(
      this.itemSettings.itemName,
      'selectedItem',
      this.selectedTicket
    );
  }

  public onTicketEdit(ticket: Ticket): void {
    const popupTitle: string = this.translateService.instant(
      'pages.tickets.components.ticket-form.update-ticket.label'
    );
    this.openModalToManageTicket(ticket, popupTitle);
  }

  async openDialogWorkOrder(event: Ticket): Promise<void> {
    if (this.entityTypeWorkOrder == null) {
      this.entityTypeWorkOrder =
        await this.entityTypeService.getAllEntityTypeDataByName('WorkOrder');
    }
    this.newWorkOrder = new WorkOrder({
      genericRelation: new GenericRelation({
        sourceId: event.id,
        sourceEntityType: 'Ticket',
        targetEntityType: 'WorkOrder',
      }),
      isRecurring: false,
      recurrence: null,
      name: event.name,
      description: event.description,
      resourceId: event?.rootResourceId != null ? event.rootResourceId : null,
    });
    if (event.dueDate != null && event.dueDate != '') {
      this.newWorkOrder.plannedFinishDate = this.setDateTimeToEndOfDay(
        new Date(this.utilityService.getIsoStringInLocalTime(event.dueDate))
      );
    }

    this.displayWorkOrderModal = true;
  }

  private setDateTimeToEndOfDay(date: Date): Date {
    return new Date(date.setHours(23, 59, 0));
  }

  onExecutedAction(): void {
    this.newWorkOrder = null;
    this.displayWorkOrderModal = null;
  }

  onHideDialog(): void {
    this.newWorkOrder = null;
  }

  onCreateNewTicketClick(event: Ticket): void {
    const newTicket: Ticket = new Ticket({
      genericRelation: new GenericRelation({
        sourceId: event.id,
        sourceEntityType: 'Ticket',
        targetEntityType: 'Ticket',
      }),
      ticketPriorityId: event.ticketPriorityId,
      ticketGroupId: event.ticketGroupId != null ? event.ticketGroupId : null,
      rootResourceId:
        event.rootResourceId != null ? event.rootResourceId : null,
      resourceId: event.resourceId != null ? event.resourceId : null,
      isPublic: false,
      description: event.description,
      ticketUsers:
        event.ticketUsers != null && event.ticketUsers.length > 0
          ? event.ticketUsers
          : [],
    });
    if (event.dueDate != null && event.dueDate != '') {
      newTicket.dueDate = event.dueDate;
    }
    const popupTitle: string = this.translateService.instant(
      'pages.tickets.components.ticket-form.new-ticket.label'
    );
    this.openModalToManageTicket(newTicket, popupTitle);
  }
}
