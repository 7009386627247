import { PrgMenuItem } from '@prg/prg-core-lib';
import { DashboardNames } from '../Utilities/helpers';

/**
 * Constant that defines the menu items for main menu
 */
export const SIDEBAR_MENU: PrgMenuItem[] = [
  new PrgMenuItem({
    id: 'home',
    icon: 'pi pi-home',
    routerLink: [''],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
  }),

  new PrgMenuItem({
    id: 'indicators',
    icon: 'pi pi-chart-line',
    routerLink: ['/indicators'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'view-indicators',
    resource: 'dashboard',
  }),

  new PrgMenuItem({
    id: 'tickets',
    icon: 'pi pi-ticket',
    routerLink: ['/dashboard/display/' + DashboardNames.TicketsManager],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'dashboard',
    resource: 'ticket',
  }),
  new PrgMenuItem({
    id: 'tasks-board',
    icon: 'pi pi-check-square',
    routerLink: ['/tasks-board'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'kanban',
    resource: 'ticket',
  }),
  new PrgMenuItem({
    id: 'work-orders',
    icon: 'pi pi-calendar',
    routerLink: ['/work-orders'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list-manager',
    resource: 'workorder',
  }),
  new PrgMenuItem({
    id: 'resources',
    icon: 'pi pi-th-large',
    routerLink: ['/resources'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'menu',
    resource: 'resource',
  }),
  new PrgMenuItem({
    id: 'checklists',
    icon: 'pi pi-list',
    routerLink: ['/checklists'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'menu',
    resource: 'checklist',
  }),
  new PrgMenuItem({
    id: 'user-availabilities',
    icon: 'pi pi-clock',
    routerLink: ['/user-availabilities'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'menu',
    resource: 'useravailability',
  }),

  new PrgMenuItem({
    id: 'manage',
    icon: 'pi pi-briefcase',
    translationBasePath: 'components.sidebar.',
    permission: 'menu',
    resource: 'management',
    items: [
      new PrgMenuItem({
        id: 'clients',
        icon: 'pi pi-id-card',
        routerLink: ['/entity-types/client'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
        permission: 'list',
        resource: 'client',
      }),
      new PrgMenuItem({
        id: 'contracts',
        icon: 'pi pi-book',
        routerLink: ['/entity-types/contract'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
        permission: 'list',
        resource: 'contract',
      }),
      new PrgMenuItem({
        id: 'consumables',
        icon: 'pi pi-palette',
        routerLink: ['/entity-types/consumable'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
        permission: 'menu',
        resource: 'consumable',
      }),

      new PrgMenuItem({
        id: 'models',
        icon: 'fa-solid fa-shapes',
        routerLink: ['/entity-types/model'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
        permission: 'menu',
        resource: 'model',
      }),

      new PrgMenuItem({
        id: 'operation-group',
        icon: 'pi pi-wrench ',
        routerLink: ['/entity-types/operationgroup'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
        permission: 'menu',
        resource: 'operationgroup',
      }),
      new PrgMenuItem({
        id: 'ticket-group',
        icon: 'pi pi-check-square ',
        routerLink: ['/entity-types/ticketgroup'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
        permission: 'menu',
        resource: 'ticketgroup',
      }),
      new PrgMenuItem({
        id: 'work-order-types',
        icon: 'pi pi-calendar ',
        routerLink: ['/entity-types/lookuptableitem'],
        queryParams: { LookupTableId: 'workordertypes' },
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
        permission: 'list',
        resource: 'lookuptableitem',
      }),
      new PrgMenuItem({
        id: 'departments',
        icon: 'pi pi-building',
        translationBasePath: 'components.sidebar.',
        routerLink: ['/entity-types/department'],
        routerLinkActiveOptions: { exact: true },
        permission: 'menu',
        resource: 'department',
      }),
      new PrgMenuItem({
        id: 'tags',
        icon: 'pi pi-tags',
        routerLink: ['/entity-types/tag'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
        permission: 'menu',
        resource: 'tag',
      }),
      new PrgMenuItem({
        id: 'users',
        icon: 'pi pi-users',
        routerLink: ['/users'],
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.',
        permission: 'listmanager',
        resource: 'userprofile',
      }),

      new PrgMenuItem({
        id: 'config-items',
        icon: 'pi pi-cog',
        translationBasePath: 'components.sidebar.',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes, max-len
          "['/' + this.configItemsConfig.configurationItemsRoutingBasePath + '/' + this.configItemsConfig.configurationItemsRoutingAdminPath]",
        routerLinkActiveOptions: { exact: true },
        permission: 'list-admin',
        resource: 'configurationitem',
      }),
      new PrgMenuItem({
        id: 'config-items-notifications',
        icon: 'pi pi-bell',
        translationBasePath: 'components.sidebar.',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes, max-len
          "['/' + this.configItemsConfig.configurationItemsRoutingBasePath + '/' + this.configItemsConfig.configurationItemsRoutingNotificationsPath]",
        routerLinkActiveOptions: { exact: true },
        permission: 'list-notifications',
        resource: 'configurationitem',
      }),
    ],
  }),

  /*new PrgMenuItem({
    id: 'operation-group',
    icon: 'pi pi-wrench ',
    routerLink: ['/entity-types/operationgroup'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'operationgroup',
  }),
  new PrgMenuItem({
    id: 'ticket-group',
    icon: 'pi pi-check-square ',
    routerLink: ['/entity-types/ticketgroup'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'operationgroup',
  }),
  new PrgMenuItem({
    id: 'consumables',
    icon: 'pi pi-palette',
    routerLink: ['/entity-types/consumable'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'menu',
    resource: 'consumable',
  }),

  new PrgMenuItem({
    id: 'clients',
    icon: 'pi pi-id-card',
    routerLink: ['/entity-types/client'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'client',
  }),

  new PrgMenuItem({
    id: 'contracts',
    icon: 'pi pi-book',
    routerLink: ['/entity-types/contract'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'contract',
  }),

  new PrgMenuItem({
    id: 'users',
    icon: 'pi pi-users',
    routerLink: ['/users'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'userprofile',
  }),
  new PrgMenuItem({
    id: 'user-availabilities',
    icon: 'pi pi-clock',
    routerLink: ['/user-availabilities'],
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'useravailability',
  }),*/

  /* new PrgMenuItem({
    id: 'entity-types',
    icon: 'pi pi-database',
    // eslint-disable-next-line @typescript-eslint/quotes
    routerLinkString: "['/' + this.entityTypesConfig.entityTypeBasePath]",
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'entitytype',
  }),*/
  /*new PrgMenuItem({
    id: 'lookup-table',
    icon: 'pi pi-map',
    // eslint-disable-next-line @typescript-eslint/quotes
    routerLinkString: "['/' + this.lookupTableConfig.lookupTableBasePath]",
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'lookuptable',
  }),*/
  new PrgMenuItem({
    id: 'queries',
    icon: 'pi pi-filter',
    // eslint-disable-next-line @typescript-eslint/quotes
    routerLinkString: "['/' + this.queriesConfig.queriesRoutingPath]",
    routerLinkActiveOptions: { exact: true },
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'query',
  }),
  new PrgMenuItem({
    id: 'dashboard',
    icon: 'pi pi-chart-bar',
    translationBasePath: 'components.sidebar.',
    permission: 'list',
    resource: 'dashboard',

    items: [
      new PrgMenuItem({
        icon: 'pi pi-plus-circle',
        id: 'create',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes
          "['/'  + this.dashboardConfig.dashboardRoutingBasePath + '/' + this.dashboardConfig.dashboardConfigRoutingPath] +'/new'",
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.dashboard.',
      }),
      new PrgMenuItem({
        icon: 'pi pi-list',
        id: 'list',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes
          "['/' + this.entityTypesConfig.entityTypeBasePath + '/dashboard']",
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.dashboard.',
      }),
      new PrgMenuItem({
        icon: 'pi pi-eye',
        id: 'display',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes
          "['/' + this.dashboardConfig.dashboardRoutingBasePath + '/' + this.dashboardConfig.dashboardDisplayRoutingPath]",
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.dashboard.',
      }),
    ],
  }),

  /* {
    id: 'config-items',
    icon: 'pi pi-cog',
    translationBasePath: 'components.sidebar.',
    routerLinkString:
      // eslint-disable-next-line @typescript-eslint/quotes, max-len
      "['/' + this.configItemsConfig.configurationItemsRoutingBasePath + '/' + this.configItemsConfig.configurationItemsRoutingAdminPath]",
    routerLinkActiveOptions: { exact: true },
    permission: 'list',
    resource: 'configurationitems',
    /!*items: [
      {
        icon: 'pi pi-user-edit',
        id: 'admin',
        routerLinkString:
        // eslint-disable-next-line @typescript-eslint/quotes, max-len
          "['/' + this.configItemsConfig.configurationItemsRoutingBasePath + '/' + this.configItemsConfig.configurationItemsRoutingAdminPath]",
        routerLinkActiveOptions: { exact: true },
        translationBasePath: 'components.sidebar.config-items.',
      },
    ],*!/
  },*/
];

/**
 * Constant that defines the menu items for user menu
 */
export const USER_MENU_HEADER: PrgMenuItem[] = [
  {
    id: 'user-menu',
    items: [
      { separator: true },
      {
        icon: 'pi pi-user',
        id: 'my-account', //Key da tradução
        visibleExpression: '!!this.user',
        routerLink: '/users/my-account/profile',
      },
      {
        icon: 'pi pi-sign-out',
        commandString: 'this.onClickLogout',
        id: 'logout',
        visibleExpression: '!!this.user',
      },
      {
        icon: 'pi pi-sign-in',
        visibleExpression: '!this.user',
        id: 'login',
        routerLinkString:
          // eslint-disable-next-line @typescript-eslint/quotes
          "['/' + this.authConfig.authRoutingBasePath + '/' + this.authConfig.authRoutingLoginPath,]",
      },
    ],
  },
];
/**
 * Constant that defines the menu items for language menu on header
 */

export const LANGUAGE_MENU_HEADER: PrgMenuItem[] = [
  {
    id: 'language-menu',
    items: [
      { separator: true },
      {
        id: 'pt',
        commandString: '($event) => this.changeLanguage($event.item.id)',
      },
      {
        id: 'en',
        commandString: '($event) => this.changeLanguage($event.item.id)',
      },
    ],
  },
];
